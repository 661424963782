import React from 'react';
import Moment from 'moment';
import TimeComponent from './TimeComponent';
import LineComponent from './LineComponent';
import panzoom from 'pan-zoom';
/*import gps_img_1 from '../../../assets/media/images/gps/GPS_01.png'
import gps_img_2 from '../../../assets/media/images/gps/GPS_02.png'
import gps_img_3 from '../../../assets/media/images/gps/GPS_03.png'
import gps_img_4 from '../../../assets/media/images/gps/GPS_04.png'
import gps_img_5 from '../../../assets/media/images/gps/GPS_05.png'
import gps_img_6 from '../../../assets/media/images/gps/GPS_06.png'
import gps_img_7 from '../../../assets/media/images/gps/GPS_07.png'
import gps_img_8 from '../../../assets/media/images/gps/GPS_08.png'
import gps_img_9 from '../../../assets/media/images/gps/GPS_09.png'
import gps_img_10 from '../../../assets/media/images/gps/GPS_10.png'
import gps_img_11 from '../../../assets/media/images/gps/GPS_11.png'
import gps_img_12 from '../../../assets/media/images/gps/GPS_12.png'
import gps_img_13 from '../../../assets/media/images/gps/GPS_13.png'
import gps_img_14 from '../../../assets/media/images/gps/GPS_14.png'*/


import gps_img_1 from '../../../assets/media/images/Icons2/GPS_01.png'
import gps_img_2 from '../../../assets/media/images/Icons2/GPS_02.png'
import gps_img_3 from '../../../assets/media/images/Icons2/GPS_03.png'
import gps_img_4 from '../../../assets/media/images/Icons2/GPS_04.png'
import gps_img_5 from '../../../assets/media/images/Icons2/GPS_05.png'
import gps_img_6 from '../../../assets/media/images/Icons2/GPS_06.png'
import gps_img_7 from '../../../assets/media/images/Icons2/GPS_07.png'
import gps_img_8 from '../../../assets/media/images/Icons2/GPS_08.png'
import gps_img_9 from '../../../assets/media/images/Icons2/GPS_09.png'
import gps_img_10 from '../../../assets/media/images/Icons2/GPS_10.png'
import gps_img_11 from '../../../assets/media/images/Icons2/GPS_11.png'
import gps_img_12 from '../../../assets/media/images/Icons2/GPS_12.png'
import gps_img_13 from '../../../assets/media/images/Icons2/GPS_13.png'
import gps_img_14 from '../../../assets/media/images/Icons2/GPS_14.png'
import {
  MDBIcon
} from 'mdbreact';

class SpagComponent extends React.Component {

  piersHeaderCellWidth = 80;
  headersFootersHeight = 223;
  initialzoom = 205;
  minzoom = 140;
  maxzoom = 300;

  constructor(props) {
      super(props);
      if (this.props.mode === "linestart") {
        this.state = {
              x: this.modelToViewXBounded(this.props.linestartx, this.initialzoom),
              y: this.modelToViewYBounded(this.props.linestarty, this.initialzoom),
              zoom: this.initialzoom,
        }
      } else if (this.props.mode === "nexthour") {
        let modelx = this.getHourX(Moment().hour()+1);
        let modely = this.getClosestPierY();
        this.state = {
          x: this.modelToViewXBounded(modelx, this.initialzoom),
          y: this.modelToViewYBounded(modely, this.initialzoom),
          zoom: this.initialzoom
        }
      } else {
        this.state = {
          x: 0,
          y: 0,
          zoom: this.initialzoom
        }
      }
    };

  modelToViewX = (modelx, zoom) => {
    let z = zoom;
    if (z === undefined) {
      zoom = this.state.zoom;
    }
    return -modelx * zoom / 100;// - this.piersHeaderCellWidth;
  }

  modelToViewY = (modely, zoom) => {
    let z = zoom;
    if (z === undefined) {
      zoom = this.state.zoom;
    }
    return -modely * zoom / 100;
  }

  boundViewX = (viewx, zoom) => {
    if (viewx > 0) {
      viewx = 0;
    }
    if (zoom === undefined) {
      zoom = this.state.zoom;
    }
    let widthSize = Math.min(500, window.innerWidth);
    if (viewx < -zoom / 100 * this.props.maxmodelx + widthSize-this.piersHeaderCellWidth) {
      viewx = -zoom / 100 * this.props.maxmodelx + widthSize-this.piersHeaderCellWidth;
    }
    return viewx;
  }

  boundViewY = (viewy, zoom) => {
    if (viewy > 0) {
      viewy = 0;
    }
    if (viewy < this.modelToViewY(this.props.maxmodely, zoom) + window.innerHeight - this.headersFootersHeight) {
      viewy = this.modelToViewY(this.props.maxmodely, zoom) + window.innerHeight - this.headersFootersHeight;
    }
    return viewy;
  }

  boundZoom = (zoom) => {

    if (zoom < this.minzoom)
      return this.minzoom;
    if (zoom > this.maxzoom)
      return this.maxzoom;
    return zoom;
  }

  modelToViewXBounded = (modelx, zoom) => {
    return this.boundViewX(this.modelToViewX(modelx, zoom), zoom);
  }

  modelToViewYBounded = (modely, zoom) => {
    return this.boundViewY(this.modelToViewY(modely, zoom), zoom);
  }

  componentDidMount() {
    let unpanzoom = panzoom(
      document.getElementById('spag'),
      e => {
        this.setState((state, props) => {
            let newzoom = this.boundZoom(state.zoom - e.dz);
            let xDistanceFromTopLeft = Math.abs(state.x) + e.x;
            let yDistanceFromTopLeft = Math.abs(state.y) + e.y;
            let xResizedDistanceFromTopLeft = xDistanceFromTopLeft * newzoom / state.zoom;
            let yResizedDistanceFromTopLeft = yDistanceFromTopLeft * newzoom / state.zoom;
            let newx = this.boundViewX(-(xResizedDistanceFromTopLeft - e.x) + e.dx, newzoom);
            let newy = this.boundViewY(-(yResizedDistanceFromTopLeft - e.y) + e.dy, newzoom);
            return {
              x: newx,
              y: newy,
              zoom: newzoom
            }
          }
        );

        requestAnimationFrame(() => {
        });
      },
      {
        onStart: e => {},

        onEnd: e => {},

        onDoubleTap: e => {},

        passive: false,
      }
    );
    this.setState({unpanzoom: unpanzoom});
  }

  componentWillUnmount() {
    this.state.unpanzoom();
    this.props.onSetXCoord(-this.state.x*100/this.state.zoom, this.props.originalLinestartx);
    this.props.onSetYCoord(-this.state.y*100/this.state.zoom, this.props.originalLinestarty);
  }

  getHourX = (hour) => {
    if (hour === undefined) {
      hour = Moment().hour();
    }
    if (hour < 0) {
      hour = 0;
    }
    if (hour > 23) {
      hour = 23;
    }
    let hourX = this.props.hours[hour];
    if (hourX === undefined) {
      let keyArray = Object.keys(this.props.hours).filter(k => !isNaN(parseInt(k))).sort((a,b) => a-b);
      let minKey = keyArray[0];
      let maxKey = keyArray[keyArray.length-1];
      if (hour < minKey) {
        hourX = this.props.hours[minKey];
      } else if (hour > maxKey) {
        hourX = this.props.hours[maxKey];
      }
    }
    return hourX;
  }

  getClosestPierY = () => {
    return this.props.piers[this.props.closestPier.pier.name];
  }

  handleRescueRingClick = event => {
    let spagHeight = window.innerHeight - this.headersFootersHeight;
    let halfSpagHeight = spagHeight / 2;

    let hour = Moment().hour();
    //let hourX = this.props.hours[hour];
    //let hour = 17;
    let hourX = this.getHourX(hour);
    this.setState({
      x: this.modelToViewXBounded(hourX),
      y: this.props.closestPier !== undefined ? this.boundViewY(this.modelToViewY(this.getClosestPierY()) + halfSpagHeight) :
      this.boundViewY(halfSpagHeight)
    });
  }

  handleSpagDoubleClick = event => {
    if (event.detail === 2) {
      // double click
      this.setState({
        x: this.modelToViewXBounded(this.props.originalLinestartx),
        y: this.modelToViewYBounded(this.props.originalLinestarty)
      });
      this.props.onSetXCoord(0, 0);
      this.props.onSetYCoord(0, 0);
    }
  }


  render() {
    let letArraygps = [ "", gps_img_1, gps_img_2, gps_img_3, gps_img_4, gps_img_5, gps_img_6, gps_img_7, gps_img_8, gps_img_9, gps_img_10, gps_img_11, gps_img_12, gps_img_13, gps_img_14];
    return (
        <div id="spag">
          <img src={letArraygps[this.props.selected]}
               className="spag-img-component"
               onClick={this.handleRescueRingClick}/>
          <TimeComponent x={this.state.x}
                         y={this.state.y}
                         maxmodelx={this.props.maxmodelx}
                         hours={this.props.hours}
                         zoom={this.state.zoom}/>
          <div className="horizontal-component">
            <LineComponent x={this.state.x}
                           y={this.state.y}
                           zoom={this.state.zoom}
                           piers={this.props.piers}/>
            <img src={this.props.image}
                style={{
                  transform: `translate(${this.state.x}px, ${this.state.y}px)`,
                  width: this.state.zoom / 100 * this.props.maxmodelx,
                  height: this.state.zoom / 100 * this.props.maxmodely
                }}
                onClick={this.handleSpagDoubleClick}/>
          </div>

        </div>
    );
  }
}
export default SpagComponent;
